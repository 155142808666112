import { css, type SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Headline } from '@sumup-oss/circuit-ui';
import type { Theme } from '@sumup-oss/design-tokens';
import type { FC, ReactNode } from 'react';

type Props = {
  theme?: Theme;
  productsCount: number;
  children?: ReactNode;
};

const base = ({ theme, productsCount }: Props): SerializedStyles => css`
  font-size: var(--cui-typography-body-two-font-size);
  line-height: var(--cui-typography-body-two-line-height);
  margin-top: 0;
  user-select: none;
  width: 100%;

  ${theme.mq.mega} {
    margin-bottom: var(--cui-spacings-bit);
  }

  ${theme.mq.kilo} {
    font-size: var(--cui-typography-headline-three-font-size);
    line-height: var(--cui-typography-headline-three-line-height);
  }

  ${theme.mq.mega} {
    height: auto;
    user-select: auto;
  }

  ${theme.mq.giga} {
    ${productsCount === 4
      ? `
        font-size: var(--cui-typography-headline-three-font-size);
        line-height: var(--cui-typography-headline-three-line-height);
        `
      : `
        font-size: var(--cui-typography-headline-two-font-size);
        line-height: var(--cui-typography-headline-two-line-height);
      `};
    margin-top: var(--cui-spacings-mega);
    display: block;
  }
`;

const StyledProductName = styled(Headline, {
  shouldForwardProp: (propName) => propName !== 'productsCount',
})(base);

export const ProductName: FC<Props> = ({ children, productsCount }) => (
  <StyledProductName productsCount={productsCount} as="h2">
    {children}
  </StyledProductName>
);
