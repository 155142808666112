import type { ParsedUrlQuery } from 'querystring';

import type {
  GetServerSideProps,
  GetServerSidePropsResult,
  NextPage,
} from 'next';
import queryString from 'query-string';

import type { AppProps } from 'pages/_app.page';
import { ProductSelection } from 'productSelection/pages/ProductSelection';
import type { Props as ProductSelectionProps } from 'productSelection/pages/ProductSelection/ProductSelection';
import { getEnabledShopCountriesWithLocales } from 'productSelection/services/country';
import type { Channel } from 'shared/constants/Channel';
import { getCountryCodeFromNextLocale } from 'shared/i18n/helpers';
import { setStaleWhileRevalidateCacheControl } from 'shared/infra/cacheControl';
import {
  getAvailableChannelsByCountry,
  getCatalogForCountryAndChannel,
  getCountriesWithLocales,
  getExperimentalTaxNoticesEntries,
} from 'shared/infra/contentful';
import type { LOCALE_CODE } from 'shared/infra/contentful/contentful';
import { previewInfo } from 'shared/infra/contentful/preview';
import {
  getGalleryPricesByCode,
  getPricesForMarket,
  getPurchaseLimitBySkuCode,
} from 'shared/infra/storefront/markets/prices';
import { isValidChannel } from 'shared/services/channel';
import { convertImageToPng } from 'shared/services/imageUrl';
import { isFlagEnabledOnServer } from 'shared/services/optimizely';
import { TTPOI_AT_TOP } from 'shared/services/optimizely/flag-config';
import { setGlobalCatalogFromServerSide } from 'shared/services/OrderInformationService.globals';
import {
  filterProductCatalog,
  parseContentfulDigitalProducts,
  parseContentfulProducts,
  parseContentfulStoreProducts,
  parseContentfulUpsellProducts,
} from 'shared/services/ProductService';
import { SessionProvider } from 'shared/sso/SessionProvider';
import type { ProductSelectionServerSidePropsContext } from 'shared/types/static-props';
import { getLast } from 'utils/querystring';

interface Props {
  query: ParsedUrlQuery & {
    products?: string[];
    product?: string;
    prc?: string | string[];
  };
  channel: Channel;
  locale: LOCALE_CODE;
  trackingId: string;
}

const DISABLED_CHANNELS: Channel[] = [
  'quotes',
  'recommendation',
  'shipping',
  'sidekick',
];

const redirectToProductDetailPage = ({
  query,
  channel,
  locale,
  trackingId,
}: Props): GetServerSidePropsResult<Props> => {
  // Remove products/product param from query to avoid redirect loops.
  const { products, product: productParam, ...queryParams } = query;

  const parsedQuery = queryString.stringify(queryParams);

  // Omit /signup from signup shop redirects
  const channelRedirectRoute = channel !== 'signup' ? `${channel}/` : '';

  return {
    redirect: {
      destination: [
        `/${locale}/${channelRedirectRoute}product-selection/${trackingId}`,
        parsedQuery ? `?${parsedQuery}` : '',
      ].join(''),
      permanent: false,
    },
  };
};

const Page: NextPage<ProductSelectionProps & AppProps> = (props) => (
  <SessionProvider>
    <ProductSelection {...props} />
  </SessionProvider>
);

export const getServerSideProps: GetServerSideProps = async (
  context: ProductSelectionServerSidePropsContext,
) => {
  const {
    locale,
    params: { channel = 'signup' } = {},
    res,
    query,
    preview,
  } = context;
  if (DISABLED_CHANNELS.includes(channel)) {
    return { notFound: true };
  }

  setGlobalCatalogFromServerSide(channel);

  const countryCode = getCountryCodeFromNextLocale(locale);

  const countryAvailableChannels =
    await getAvailableChannelsByCountry(countryCode);

  /**
   * Prevents status 500 pages to be displayed for users
   * accessing the shop with an invalid shop channel.
   */
  if (!isValidChannel(channel, countryAvailableChannels)) {
    return {
      notFound: true,
    };
  }

  const catalog = await getCatalogForCountryAndChannel({
    countryCode,
    channel,
    locale,
  });

  previewInfo().setPreview(preview);

  // Support '?product' parameter as a fallback for '?products'
  const productsQueryParam = query.products || query.product;
  const productsOnQuery = Array.isArray(productsQueryParam)
    ? productsQueryParam
    : productsQueryParam?.split(',');

  setStaleWhileRevalidateCacheControl(res);

  const productsEntries = catalog.products || [];
  const digitalProductsEntries = catalog.digitalProducts || [];

  /**
   * The list of countries is used to display country and language dropdown
   * in the bottom of the page
   */
  const countriesWithLocalesEntries = await getCountriesWithLocales(locale);

  const notificationsContent = catalog.uiNotifications.fields;
  const cartContent = catalog.uiCart.fields;
  const pageContent = catalog.uiProductSelectionPage.fields;

  const sections = pageContent.sections || null;
  const metaReference =
    pageContent.metaReference?.fields.productSelectionPage?.fields;
  const productDetailsContent = catalog.uiProductDetailsPage?.fields;

  // TODO: after various product selection experiments have concluded,
  // understand which pieces of content are needed (ex, icon is not in all exp
  // designs) and map only those to pass as props.
  const transparentPricingIconUrl =
    pageContent.transparentPricingComponent?.fields?.icon?.fields?.file?.url ||
    '';
  const transparentPricingContent = {
    fields: {
      ...pageContent.transparentPricingComponent?.fields,
      icon: {
        fields: {
          ...pageContent.transparentPricingComponent?.fields?.icon?.fields,
          file: {
            ...pageContent.transparentPricingComponent?.fields?.icon?.fields
              ?.file,
            url: convertImageToPng(transparentPricingIconUrl),
          },
        },
      },
    },
  };

  const { products, digitalProducts } = filterProductCatalog(
    parseContentfulStoreProducts(productsEntries),
    parseContentfulDigitalProducts(digitalProductsEntries),
    productsOnQuery,
  );

  const marketProducts = parseContentfulProducts(
    catalog.market.fields.products ?? [],
  );

  /**
   * Since we'd like to show more product information when only a single
   * product (1) is available on the shop, we need to redirect users from
   * the product selection page to the product details page.
   *
   */

  if (products.length + digitalProducts.length === 1) {
    const trackingId =
      products.length > 0
        ? products[0].trackingId // the "physical" (non-digital) product's trackingId
        : digitalProducts[0].trackingId; // the digital product's trackingId

    return redirectToProductDetailPage({
      query: { ...query, pageNotClosable: 'true' },
      channel,
      locale,
      trackingId,
    });
  }

  const upsellProducts = parseContentfulUpsellProducts(channel, countryCode);

  const pricesForMarket = await getPricesForMarket(
    catalog.market?.fields?.countryCode,
    getLast(query.prc),
  );

  const prices = getGalleryPricesByCode(
    [...products, ...upsellProducts],
    pricesForMarket,
  );

  const taxNoticeMessage = (await getExperimentalTaxNoticesEntries(locale))
    .items[0]?.fields || {
    vatValueIncludedMessage: '',
  };

  const isTTPOIFlagEnabled = isFlagEnabledOnServer(TTPOI_AT_TOP, context.req);

  return {
    props: {
      products,
      upsellProducts,
      digitalProducts,
      marketProducts,
      prices,
      marketCode: catalog.market.fields.countryCode,
      cartContent,
      pageContent: {
        ...pageContent,
        addToCartText: productDetailsContent.productCallToActionText,
        transparentPricingComponent: {
          ...transparentPricingContent,
        },
      },
      notificationsContent,
      commerceLayerClient: process.env.NEXT_PUBLIC_COMMERCE_LAYER_CLIENT_ID,
      countries: getEnabledShopCountriesWithLocales(
        countriesWithLocalesEntries.items,
      ),
      metaReference,
      footerContent: catalog.footer.fields,
      isDiscountPriceEnabled: !!catalog.market.fields.enableDiscountPrice,
      taxMessage: taxNoticeMessage.vatValueIncludedMessage,
      sections,
      defaultTaxRate: pricesForMarket.data.attributes.tax_rate,
      purchaseLimitsBySkuCodes: getPurchaseLimitBySkuCode(pricesForMarket),
      isLogoClickable: catalog.uiNavigationBar.fields.isLogoClickable ?? true,
      isTTPOIFlagEnabled,
    },
  };
};

export default Page;
