import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Grid } from '@sumup-oss/circuit-ui/legacy';
import type { FC, PropsWithChildren } from 'react';

// The media query values below are based on investigation of where the footer
// breaks away from the bottom of the screen on the product selection page
// or covers other content making it inaccessible.
const WIDTH_BREAKPOINT_FOR_FOOTER_STYLE = 1600;
const HEIGHT_BREAKPOINT_FOR_FOOTER_STYLE = 900;
const DEVICE_WIDTH_BREAKPOINT = 812;

function getHeightBreakpointForFooterStyle(isDefault = true): number {
  if (isDefault) {
    return HEIGHT_BREAKPOINT_FOR_FOOTER_STYLE;
  }

  return HEIGHT_BREAKPOINT_FOR_FOOTER_STYLE + 150;
}

export const StyledGrid: FC<PropsWithChildren> = styled(Grid)(
  ({ theme }) => css`
    margin-bottom: var(--cui-spacings-giga);
    margin-top: var(--cui-spacings-giga);

    ${theme.mq.untilKilo} {
      padding: 0 var(--cui-spacings-mega);
    }
  `,
);

export const TransparentFeesAndFooter = styled.div<{
  showProductsOnOneLine?: boolean;
  isGridExperiment?: boolean;
  hasProductsInCart?: boolean;
}>(
  ({
    showProductsOnOneLine = false,
    isGridExperiment = false,
    hasProductsInCart = false,
  }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    width: 100%;
    // calculate a z-index over that of the modal so the CTA remains visible and operable
    z-index: var(--cui-z-index-modal) + 1;

    ${showProductsOnOneLine &&
    !hasProductsInCart &&
    css`
      @media (min-width: ${WIDTH_BREAKPOINT_FOR_FOOTER_STYLE}px) {
        position: fixed;
      }
    `}

    ${showProductsOnOneLine &&
    css`
      @media screen and (min-height: ${getHeightBreakpointForFooterStyle(
          isGridExperiment,
        )}px) and (min-device-width: ${DEVICE_WIDTH_BREAKPOINT}px) {
        position: fixed;
      }
    `}
  `,
);
