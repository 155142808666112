import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Headline } from '@sumup-oss/circuit-ui';
import type { FC } from 'react';

import { SectionColumn } from 'productSelection/components/SectionColumn';
import type { IColumnLayoutFields } from 'shared/infra/contentful/contentful';

export interface Props {
  spanKey: string;
  columnLayoutFields: IColumnLayoutFields;
}

const ColLayoutHeadline = styled(Headline)(
  ({ theme }) => css`
    margin-bottom: var(--cui-spacings-mega);
    text-align: center;

    ${theme.mq.mega} {
      font-size: 64px;
      line-height: 64px;
    }
  `,
);

const ColLayoutSubtitle = styled(Body)`
  text-align: center;
  margin-bottom: var(--cui-spacings-giga);
`;

export const ColumnLayout: FC<Props> = ({ spanKey, columnLayoutFields }) => (
  <span key={spanKey}>
    <ColLayoutHeadline as="h2" size="l">
      {columnLayoutFields.headline}
    </ColLayoutHeadline>
    {columnLayoutFields.subtitle && (
      <ColLayoutSubtitle size="l">
        {columnLayoutFields.subtitle}
      </ColLayoutSubtitle>
    )}
    {columnLayoutFields.columns &&
      columnLayoutFields.columns.map((column) => (
        <SectionColumn
          columnKey={column.fields.headline}
          key={column.fields.headline}
          columnFields={column.fields}
        />
      ))}
  </span>
);
