import { css, type SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Body } from '@sumup-oss/circuit-ui';
import type { Theme } from '@sumup-oss/design-tokens';
import type { FC } from 'react';

type StyleProps = {
  theme?: Theme;
};

type Props = {
  shortBenefits?: string;
};

const base = ({ theme }: StyleProps): SerializedStyles => css`
  font-size: 13px;
  margin: 0;

  ${theme.mq.mega} {
    font-size: var(--cui-typography-body-two-font-size);
    line-height: var(--cui-typography-body-two-line-height);
    margin-bottom: var(--cui-spacings-byte);
  }
`;

const BenefitsContent = styled(Body)(base);

export const ShortBenefits: FC<Props> = ({ shortBenefits }) => (
  <BenefitsContent size="m">{shortBenefits}</BenefitsContent>
);
