import { css, type SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Body } from '@sumup-oss/circuit-ui';
import type { Theme } from '@sumup-oss/design-tokens';

type Props = {
  isFree?: boolean;
  theme?: Theme;
};

const base = ({ theme }: Props): SerializedStyles => css`
  font-size: var(--cui-typography-headline-two-font-size);
  font-weight: var(--cui-font-weight-bold);
  line-height: var(--cui-typography-headline-two-line-height);
  margin: var(--cui-spacings-byte) 0 0;

  ${theme.mq.giga} {
    font-size: var(--cui-typography-headline-one-font-size);
    line-height: var(--cui-typography-headline-one-line-height);
  }
`;

const free = ({ isFree }: Props): SerializedStyles => css`
  ${isFree && 'color: var(--cui-border-success);'}
`;

export const PriceComponent = styled(Body, {
  shouldForwardProp: (propName) => propName !== 'isFree',
})(base, free);
