import { css } from '@emotion/react';
import { Body } from '@sumup-oss/circuit-ui';
import type { FC } from 'react';

type Props = {
  taxMessage: string;
  light?: boolean;
};

const TaxMessage: FC<Props> = ({ taxMessage, light = false }) => {
  if (!taxMessage) {
    return null;
  }

  return (
    <Body
      css={() => css`
        color: ${light ? 'var(--cui-fg-subtle)' : 'var(--cui-fg-normal)'};
        font-size: 10px;
        line-height: 10px;
      `}
      color="subtle"
      size="s"
    >
      {taxMessage}
    </Body>
  );
};

export default TaxMessage;
